import axios from 'axios';

const DEFAULT_HEADERS = {
  store: '0',
  Accept: 'application/json',
}

const createApi = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: DEFAULT_HEADERS,
  });

const api = createApi();


export const getCompanyBasicInfo = async (id) => {
  const url = `/informacoesSite`
  const { data } = await api.get(url, { headers: {
    ...DEFAULT_HEADERS,
    store: id
  }});
  return data
}