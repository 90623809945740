import Container from '@material-ui/core/Container';
import React from 'react';

import Banner from '../../components/Banner';
import Pesquisa from '../../components/Pesquisa';
import { useTitle } from '../../helpers/tittle';
import Destaque from './Destaque';
import Listagem from './Listagem';
import Servicos from './Servicos';
import './styles.css';

const styles = {
  root: {
    margin: '20px 30px',
  },
};

useTitle("Home");

const Home = () => (
  <div className="home">
    <Container maxWidth={false} className="bannerPrincipal">
      <div className="homeBannerBox">
        <Banner />
      </div>
    </Container>
    <Container className="section">
      <Pesquisa />
    </Container>
    <Container className="section">
      <Destaque />
    </Container>
    <Container className="section">
      <Listagem />
    </Container>
    {/* <Container className="section">
      <Servicos />
    </Container> */}
  </div>
);

export default Home;
