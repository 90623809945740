import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import './styles.css';

const Precos = ({ valorMax, pesquisa, handleFormChange }) => (
  <div className="precos">
    <Typography id="rangePreco" gutterBottom>
      A partir de {Number(pesquisa.precoMin).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
    </Typography>
    <Slider
      defaultValue={0}
      value={[
        parseInt(pesquisa.precoMin, 10) || 0,
        parseInt(pesquisa.precoMax, 10) || 0,
      ]}
      valueLabelFormat={(value) => Number(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
      onChange={(e, v) => {
        console.log(e,v);
        handleFormChange(e, v, 'preco')
      }}
      aria-labelledby="preco"
      color="secondary"
      valueLabelDisplay="auto"
      step={1000}
      min={0}
      max={valorMax || 0}
    />
  </div>
);

export default Precos;
