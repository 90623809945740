import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HotelIcon from '@material-ui/icons/Hotel';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import React from 'react';
import { FaToilet } from 'react-icons/fa';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import './styles.css';

const imovelCard = ({ props, pesquisa, style_type, hideDescription }) => {
  const number_of_images_per_card = 5;
  const {
    id,
    address,
    number,
    neighborhood,
    price,
    deal_type,
    type,
    city,
    state,
    m2,
    constructed_m2,
    bedrooms: quartos,
    bathrooms: banheiros,
    parking: vagas,
    suites,
    store
  } = props;

  let { pictures } = props;
  pictures = pictures?.length > 5 ? pictures.slice(0, number_of_images_per_card) : pictures;

  const theme = useTheme();
  return (
    <div
      className={`imovelContainer ${style_type} ${pesquisa ? 'result' : ''}`}
    >
      <div className="dadosImovel">
        <Carousel
          className="carousel carousel-slider control-arrow"
          showThumbs={false}
          infiniteLoop={pictures?.length !== 1}
          showStatus={false}
          showIndicators={pictures?.length > 1}
        >
          {pictures?.map((imagem, index) => {
            if (
              !imagem.thumbnail ||
              imagem.thumbnail.indexOf('no-image.png') !== -1
            )
              imagem.thumbnail = imagem.src;

            return (
              <div
                key={imagem.src}
                className={`imgContainer ${pesquisa ? 'result' : ''}`}
              >
                {pesquisa &&
                  (index > 0 ? (
                    <LazyLoadComponent>
                      <img
                        src={imagem.thumbnail || imagem.src}
                        alt={imagem.description}
                      />
                    </LazyLoadComponent>
                  ) : (
                    <img
                      src={imagem.thumbnail || imagem.src}
                      alt={imagem.description}
                    />
                  ))}

                {!pesquisa &&
                  (index > 0 ? (
                    <LazyLoadComponent>
                      <Link to={`/imoveis/${store}/${id}`} className="linkImg">
                        <img
                          src={imagem.thumbnail || imagem.src}
                          alt={imagem.description}
                        />
                      </Link>
                    </LazyLoadComponent>
                  ) : (
                    <Link to={`/imoveis/${store}/${id}`} className="linkImg">
                      <img
                        src={imagem.thumbnail || imagem.src}
                        alt={imagem.description}
                      />
                    </Link>
                  ))}
              </div>
            );
          })}
        </Carousel>

        <Link to={`/imoveis/${store}/${id}`} className="linkImovel">
          <div className="detalhesImovel">
            {/* Função responsável por adicionar a máscara de dinheiro */}
            <Typography variant="h5" className="price" color="primary">
              {parseInt(price, 10).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
              })}
              <span
                className="targaTipo"
                style={{ backgroundColor: theme.colors.primary }}
              >
                {deal_type}
              </span>
            </Typography>

            <p className="imovelTipo">
              {`${type} ${deal_type === 'Venda' ? 'à Venda' : 'para Locação'
                } em ${city} - ${state}`}
            </p>

            <p
              style={{ textTransform: 'capitalize' }}
              className="imoveladdress"
            >
              {`${address??''}${address && (number || neighborhood) ? ', ' : ''}${number??''}${number && neighborhood ? " - " : ""}${neighborhood??''}`}
            </p>

            <div className="imovelInfos">
              <div className="icones">
                {m2 ? (
                  <div>
                    <Tooltip title="Metros quadrados">
                      <span>
                        <SquareFootIcon />
                        <span className="description">{m2} m²</span>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
                {constructed_m2 ? (
                  <div>
                    <Tooltip title="Metros quadrados construídos">
                      <span>
                        <SquareFootIcon />
                        <span className="description">
                          {constructed_m2} m²{' '}
                        </span>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
                {quartos ? (
                  <div>
                    <Tooltip title="Quarto(s)">
                      <span>
                        <HotelIcon />
                        <span className="description">{quartos}</span>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
                {banheiros ? (
                  <div>
                    <Tooltip title="Banheiro(s)">
                      <span>
                        <FaToilet />
                        <span className="description">{banheiros}</span>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
                {vagas ? (
                  <div>
                    <Tooltip title="Vaga(s) na garagem">
                      <span>
                        <DriveEtaIcon />
                        <span className="description">{vagas}</span>
                      </span>
                    </Tooltip>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="botao">
                <Button
                  variant="contained"
                  color="primary"
                  className="linkImovelResult"
                >
                  Detalhes
                </Button>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default imovelCard;
