import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import React from 'react';

import './styles.css';

const Carregando = ({ open }) => {
  const texto = 'carregando...';
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          event.preventDefault();
        }
      }}
      disableEscapeKeyDown
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="carregando">
        <CircularProgress style={{ color: 'black' }} />
        <span>{texto}</span>
      </div>
    </Modal>
  );
};

export default Carregando;
