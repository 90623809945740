import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
