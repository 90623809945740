import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useWindowDimensions from '../../helpers';
import './styles.css';

const Banner = () => {
  const { height } = useWindowDimensions();

  const [imagens, setImagens] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/bannersSite`).then((res) => {
      setImagens(res.data);
    });
  }, []);

  let mostra = false;

  if (height > 600) {
    mostra = true;
  } else {
    mostra = false;
  }

  return (
    <LazyLoadComponent>
      <Carousel
        emulateTouch
        showThumbs={false}
        autoPlay
        transitionTime={1000}
        interval={15000}
        infiniteLoop={imagens.length !== 1}
        showStatus={false}
        showIndicators={!!(mostra && imagens.length > 1)}
        useKeyboardArrows
      >
        {imagens.map((conteudo) => (
          <div className="divBanner" key={conteudo.id}>
            <img className="imgBanner" src={conteudo.image} alt="" />
            {conteudo.title && (
              <p className="legend">
                {conteudo.title}
                {conteudo.title ? <br /> : ''}
                {conteudo.description}
              </p>
            )}
          </div>
        ))}
      </Carousel>
    </LazyLoadComponent>
  );
};

export default memo(Banner);
