import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Copyright from '../../components/Copyright';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import MenuAR from '../../components/MenuAR';
import Cadastro from '../Cadastro';
import Contato from '../Contato';
import Empresa from '../Empresa';
import Home from '../Home';
import Imoveis from '../Imoveis';
import Resultado from '../Resultado';
import ScrollToTop from './ScrollToTop';

import './styles.css';
import 'react-toastify/dist/ReactToastify.css';

import 'bootstrap-4-grid/css/grid.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

axios.defaults.headers.common.store = 0;

const App = () => {
  const [imobiliaria, setImobiliaria] = useState({
    "id": 4,
    "state": "PR",
    "city": "Campo Mourão",
    "name": "Central de Imobiliárias",
    "image": "https://img-cdi.tytotech.com.br/central-de-imobiliarias/store/af6158c3-d76c-4065-876d-2475082df519.png",
    "address": "Rua Regina Fabris Trivelatto",
    "cep": "87310-510",
    "number": 3321,
    "neighborhood": "Jardim Paulista",
    "color1": "#802320",
    "color2": "#802320",
    "color3": "#ffffff",
    "linkMaps": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15088.175883068054!2d-40.5369879!3d-19.017784!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x61308ef4d5893a76!2sCooabriel%20Sede!5e0!3m2!1spt-BR!2sbr!4v1673437301606!5m2!1spt-BR!2sbr",
    "whatsapp": "(44)44444-4444",
    "error": 0,
    "disabled": 0,
    "email": "contato@tytotech.com.br",
    "website_link": "https://site.centraldeimobiliarias.com.br",
    "complement": "Casa",
    "watermarkError": 0,
    "captcha_site_key": "0",
    "ga_tracking_id": "0",
    "working_hours": " ",
    "removed": 0,
    "phones": [
        {
            "phone": "(44)0000-0000"
        }
    ],
    "social_media": []
});

  if (imobiliaria.ga_tracking_id)
    ReactGA.initialize(imobiliaria.ga_tracking_id, {
      gaOptions: { cookieFlags: 'SameSite=None; Secure' },
    });

  const theme = createTheme({
    palette: {
      common: { white: '#fbfbfb', black: '#444444' },
      background: { default: '#fbfbfb' },
      primary: { main: imobiliaria.color1 || '#000000' },
      secondary: { main: imobiliaria.color2 || '#ffffff' },
    },
    colors: {
      primary: imobiliaria.color1 || 'gray',
      secundary: imobiliaria.color2 || '#000fff',
      tertiary: imobiliaria.color3 || 'black',
    },
    status: {
      danger: '#ed3338',
    },
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/informacoesSite`)
      .then((res) => {
        // setImobiliaria(res.data);
      });
  }, []);

  if (Object.keys(imobiliaria).length > 0)
    return (
      <div className="App">
        <Router>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            {/* <MenuAR props={imobiliaria} /> */}
            <Menu props={imobiliaria} />
            <Switch>
              <Route path="/cadastro">
                <Cadastro props={imobiliaria} />
              </Route>
              <Route path="/contato">
                <Contato props={imobiliaria} />
              </Route>
              <Route path="/empresa">
                <Empresa />
              </Route>
              {/* Quando for pesquisa, vai acessar imoveis/id, onde o id é o id do imóvel,
            então pegamos as informações do back e mostramos pro usuario, assim é
            "melhor", evitamos um roteamento profundo, é mais fácil de entender assim */}
              <Route exact path="/imoveis/:store/:id">
                <Resultado central={imobiliaria} />
              </Route>
              <Route exact path="/pesquisa">
                <Imoveis />
              </Route>
              <Route path="/pesquisa/:procura">
                <Imoveis />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              {/* Path geral, caso não encontar nenhum vai chegar nesse, e se o caminho não
            existir, só retornamos pra home */}
              <Route path="*">
                <Redirect to={{ pathname: '/' }} />
              </Route>
            </Switch>
          </ThemeProvider>
        </Router>
        <ThemeProvider theme={theme}>
          <Footer props={imobiliaria} />
          <Copyright nome={imobiliaria.name} />
        </ThemeProvider>
      </div>
    );

  return <div />;
};

export default App;
