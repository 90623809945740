import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import queryString from 'query-string';
import React, { memo, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Carregando from '../../components/Carregando';
import ImovelCard from '../../components/ImovelCard';
import PesquisaLateral from '../../components/PesquisaLateral';
import { useTitle } from '../../helpers/tittle';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Imoveis = () => {
  const parsed = queryString.parse(useLocation().search, {
    parseNumbers: true,
  });
  const [todos, setTodos] = useState([]);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [acordionState, setAcordionState] = useState(false);
  const url = useHistory();

  useTitle("Imoveis");

  useEffect(() => {
    setOpen(true);
    setTodos([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/pesquisa`, {
        params: { pesquisa: parsed },
      })
      .then((res) => {
        setTotal(res.data.total);
        setTodos(res.data.data);
        setOpen(false);
      });
  }, [url.location.search]);


  const controlaPesquisa = (pesquisa) => {
    pesquisa.pagina = 1;
    url.replace(`pesquisa?${queryString.stringify(pesquisa)}`);

    setOpen(true);
    setAcordionState(false);
    setTodos([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/pesquisa`, {
        params: { pesquisa },
      })
      .then((res) => {
        setTotal(res.data.total);
        setTodos(res.data.data);
        setOpen(false);
        window.scrollTo(0, 0);
      });
  };

  const mudaPagina = (event, value) => {
    parsed.pagina = value;
    setTodos([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/pesquisa`, {
        params: {
          pesquisa: parsed,
        },
      })
      .then((res) => {
        setTotal(res.data.total);
        setTodos([...res.data.data]);
        setOpen(false);
        url.replace(`pesquisa?${queryString.stringify(parsed)}`);
        window.scrollTo(0, 0);
      });
  };

  const classes = useStyles();

  return (
    <Container className="container">
      <div className="imoveis">
        <Accordion className="filtroResponsivo" expanded={acordionState}>
          <AccordionSummary
            onClick = {() => setAcordionState(!acordionState)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Filtros</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PesquisaLateral
              controlaPesquisa={controlaPesquisa}
              pesquisaTipo={parsed}
            />
          </AccordionDetails>
        </Accordion>

        <div className="filtroDesktop">
          <PesquisaLateral
            controlaPesquisa={controlaPesquisa}
            pesquisaTipo={parsed}
          />
        </div>

        <div className="resultadoContainer">
          {todos.length > 0
            ? todos.map((objeto) => (
                <ImovelCard
                  pesquisa
                  props={objeto}
                  key={objeto.id}
                  style_type="horizontal"
                  hideDescription
                />
              ))
            : 'Nenhum resultado encontrado!'}
          <Pagination
            count={Math.ceil(total / 10 || 1)}
            page={parseInt(parsed.pagina, 10) || 1}
            onChange={mudaPagina}
            color="secondary"
            style={{ justifyContent: 'center', display: 'flex' }}
          />
        </div>
        <Carregando open={open} />
      </div>
    </Container>
  );
};

export default memo(Imoveis);
