import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import cep from 'cep-promise';
import cpf from 'cpf';
import BRlocale from 'date-fns/locale/pt-BR';
import email from 'email-validator';
import React, { useReducer, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';

import Carregando from '../../components/Carregando';
import {
  mascaraCEP,
  mascaraCPF,
  mascaraTelefone,
  mascaraCelular,
} from '../../helpers/masks';
import { useTitle } from '../../helpers/tittle';

import './styles.css';

const Cadastro = ({ props }) => {
  const { captcha_site_key } = props;

  const [open, setOpen] = useState(false);
  const [procura, setProcura] = useState(false);
  const [erroCEP, setErroCEP] = useState(false);
  const [erroCPF, setErroCPF] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [cadastro, setCadastro] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      nome: '',
      cep: '      -   ',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cpf: '',
      rg: '',
      data_nascimento: null,
      sexo: '',
      email: '',
      fone: '',
      celular: '',
      cidade: '',
      uf: '',
    }
  );

  useTitle("Cadastro");

  /**
   * Controle do cadastro, faz uma requisição pro backend
   */
  const controlaCadastro = () => {
    if (!captcha) {
      toast.warning('Verifique que você não é um robô!.');
      return;
    }

    setOpen(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/preCadastro`, cadastro)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Pré-cadastro realizado com sucesso!');
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde.');
        }

        window.grecaptcha.reset();
        setCaptcha(false);
        setOpen(false);
      });
  };

  /**
   * Wrapper para alterar o reducer com a nova data
   * @param {Date} date data que foi retornada do picker
   */
  const controlaData = (date) => {
    setCadastro({ data_nascimento: date });
  };

  /**
   * Wrapper do reducer e também faz consulta ao CEP, preenchendo os campos,
   * caso de erro, vai deixar o campo de CEP como vermelho
   * @param {Event} evento evento do input HTML
   */
  const controlaCEP = (evento) => {
    const { name, value } = evento.target;
    setCadastro({ [name]: value });
    if (value && !value.includes('_')) {
      setProcura(true);
      setCadastro({ uf: '...' });
      setCadastro({ cidade: '...' });
      setCadastro({ bairro: '...' });
      setCadastro({ endereco: '...' });
      cep(value.replace('-', '')).then(
        // Deu tudo ok
        (result) => {
          setProcura(false);
          setErroCEP(false);
          setCadastro({ uf: result.state });
          setCadastro({ cidade: result.city });
          setCadastro({ bairro: result.neighborhood });
          setCadastro({ endereco: result.street });
        },
        // Deu algum erro
        () => {
          setProcura(false);
          setErroCEP(true);
          setCadastro({ uf: '' });
          setCadastro({ cidade: '' });
          setCadastro({ bairro: '' });
          setCadastro({ endereco: '' });
        }
      );
    }
  };

  const controlaCPF = (evento) => {
    const { name, value } = evento.target;
    setCadastro({ [name]: value });
    if (cpf.isValid(value)) {
      setErroCPF(false);
    } else {
      setErroCPF(true);
    }
  };

  const controlaEmail = (evento) => {
    const { name, value } = evento.target;
    setCadastro({ [name]: value });
    if (email.validate(value)) {
      setErroEmail(false);
    } else {
      setErroEmail(true);
    }
  };

  /**
   * Wrapper do reducer, função padrão para alterar um valor do reducer
   * @param {Evento} evento evento disparado pelo input
   */
  const controlaForm = (evento) => {
    const { name, value } = evento.target;
    setCadastro({ [name]: value });
  };

  /**
   * por ora só tá fazendo o log, mas logo logo vai mandar pro back
   * @param {Event} evento evento de submit
   */
  const controlaSubmit = (evento) => {
    evento.preventDefault();
    controlaCadastro();
  };

  return (
    <div className="formCadastroContainer">
      <div style={{ textAlign: 'center' }}>
        <h1>Venda ou Alugue o seu imóvel conosco!</h1>
        <h2>Faça o seu pré cadastro abaixo</h2>
      </div>
      <form action="submit" className="formCadastro" onSubmit={controlaSubmit}>
        <h2>Preencha os seus dados</h2>
        <div className="userContainer">
          <TextField
            required
            style={{ width: '280px' }}
            id="nome"
            label="Nome"
            name="nome"
            value={cadastro.nome}
            onChange={controlaForm}
          />
          <TextField
            required
            error={erroCPF}
            helperText={erroCPF ? 'CPF inválido ou incompleto' : ''}
            style={{ width: '156px' }}
            id="cpf"
            label="CPF"
            name="cpf"
            value={cadastro.cpf}
            onChange={controlaCPF}
            InputProps={{
              inputComponent: mascaraCPF,
            }}
          />
          <TextField
            required
            style={{ width: '100px' }}
            id="rg"
            label="RG"
            name="rg"
            value={cadastro.rg}
            onChange={controlaForm}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={BRlocale}>
            <KeyboardDatePicker
              required
              style={{ width: '195px' }}
              margin="normal"
              id="dataNascimento"
              label="Data de Nascimento"
              format="dd/MM/yyyy"
              value={cadastro.data_nascimento}
              onChange={controlaData}
              KeyboardButtonProps={{
                'aria-label': 'Trocar Data',
              }}
              invalidDateMessage="Data incompleta/inválida"
            />
          </MuiPickersUtilsProvider>
          <FormControl>
            <InputLabel htmlFor="sexo">Gênero *</InputLabel>
            <Select
              required
              native
              value={cadastro.sexo}
              onChange={controlaForm}
              inputProps={{
                name: 'sexo',
                id: 'sexo',
              }}
            >
              <option aria-label="None" value="" />
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </Select>
          </FormControl>
          <TextField
            required
            type="email"
            error={erroEmail}
            helperText={erroEmail ? 'Email inválido ou incompleto' : ''}
            style={{ width: '280px' }}
            id="email"
            label="Email"
            name="email"
            value={cadastro.email}
            onChange={controlaEmail}
          />
          <TextField
            required
            type="tel"
            style={{ width: '110px' }}
            id="fone"
            label="Telefone"
            name="fone"
            value={cadastro.fone}
            onChange={controlaForm}
            InputProps={{
              inputComponent: mascaraTelefone,
            }}
          />
          <TextField
            type="tel"
            style={{ width: '120px' }}
            id="celular"
            label="Celular"
            name="celular"
            value={cadastro.celular}
            onChange={controlaForm}
            InputProps={{
              inputComponent: mascaraCelular,
            }}
          />
        </div>
        <h2>Preencha os dados do seu imóvel</h2>
        <div className="propertyContainer">
          <TextField
            required
            error={erroCEP}
            helperText={erroCEP ? 'CEP inválido' : ''}
            style={{ width: '100px' }}
            id="cep"
            label="CEP"
            name="cep"
            value={cadastro.cep}
            onChange={controlaCEP}
            InputProps={{
              inputComponent: mascaraCEP,
            }}
          />
          <TextField
            required
            disabled={procura}
            style={{ width: '280px' }}
            id="endereco"
            label="Endereço"
            name="endereco"
            value={cadastro.endereco}
            onChange={controlaForm}
          />
          <TextField
            required
            disabled={procura}
            style={{ width: '70px' }}
            id="numero"
            label="Número"
            name="numero"
            value={cadastro.numero}
            onChange={controlaForm}
          />
          <TextField
            required
            disabled={procura}
            style={{ width: '200px' }}
            id="bairro"
            label="Bairro"
            name="bairro"
            value={cadastro.bairro}
            onChange={controlaForm}
          />
          <TextField
            required
            disabled={procura}
            style={{ width: '40px' }}
            id="uf"
            label="UF"
            name="uf"
            value={cadastro.uf}
            onChange={controlaForm}
          />
          <TextField
            required
            disabled={procura}
            style={{ width: '200px' }}
            id="cidade"
            label="Cidade"
            name="cidade"
            value={cadastro.cidade}
            onChange={controlaForm}
          />
          <TextField
            style={{ width: '280px' }}
            id="complemento"
            label="Complemento"
            name="complemento"
            value={cadastro.complemento}
            onChange={controlaForm}
          />
        </div>
        <ReCAPTCHA
          sitekey={captcha_site_key}
          onChange={(value) => setCaptcha(value)}
          onErrored={() => setCaptcha(false)}
          onExpired={() => setCaptcha(false)}
        />
        <div>
          <Button
            className="botaoEnviarCadastro"
            variant="contained"
            type="submit"
          >
            Enviar
          </Button>
        </div>
      </form>
      <ToastContainer newestOnTop position="bottom-right" />
      <Carregando open={open} />
    </div>
  );
};

export default Cadastro;
