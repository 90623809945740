import { Container } from '@material-ui/core';
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { COLORS } from "../../../temas/cores";
import './styles.css';

const ImovelLoading = ()=>(
  <SkeletonTheme 
    baseColor={COLORS.skeletonBaseColor} 
    highlightColor={COLORS.skeletonHighlightColor}
  >
    <div className='imovelLoading'>
      <Container className="container">
        <div className="imagensImovel">
            <Skeleton className="carouselImagensLoading"/> 
        </div>
        <div className="boxInfoImobiliaria">
          <Skeleton className="boxInfoImobiliariaLoading"/> 
        </div>
        <div className="infosImovel">
          <div className="infosContainer">
            <Skeleton className='infosImovelLoading'/>
          </div>
          <div className="interesseContainer">
            <Skeleton className="interesseFormsLoading"/>
            <Skeleton className="botaoWhatsAppLoaging"/>
          </div>
        </div>
      </Container>
    </div>
  </SkeletonTheme>
)

export default  ImovelLoading;