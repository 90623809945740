import { Container, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FacebookIcon from '@material-ui/icons/Facebook';
import HotelIcon from '@material-ui/icons/Hotel';
import KingBedIcon from '@material-ui/icons/KingBed';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import parse from 'html-react-parser';
import React, { useState, useRef, useEffect } from 'react';
import { FaToilet } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { FacebookShareButton } from 'react-share';
import Slider from 'react-slick';
import { toast, ToastContainer } from 'react-toastify';

import Carregando from '../../components/Carregando';
import ImovelCard from '../../components/ImovelCard';
import Interesse from '../../components/Interesse';
import { getCompanyBasicInfo } from '../../services/gestor';
import CarouselImagens from './carousel';
import './styles.css';
import ImovelLoading from './imovelLoading';

const useStyles = makeStyles({
  label: {
    color: 'white',
  },
});
const Imovel = ({ props, central }) => {

  if(props.loading) return <ImovelLoading/>;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [venda, setVenda] = useState([]);
  const [locacao, setLocacao] = useState([]);
  const theme = useTheme();
  const urlSplit = window.location.href.split('/');
  const newUrl = `${urlSplit[0]}//${urlSplit[2]}/`;
  const [slides, setSlides] = useState(4);
  const [store, setStore] = useState();

  const {
    id,
    pictures,
    address,
    number,
    neighborhood,
    characteristics,
    price,
    deal_type,
    type,
    city,
    state,
    m2,
    constructed_m2,
    bedrooms: quartos,
    bathrooms: banheiros,
    parking: vagas,
    suites,
    description,
    fci,
    fci_message,
    whatsapp,
    youtube_link,
    captcha_site_key,
    storeId
  } = props;

  const config = {
    headers: {
      type: 1,
    },
  };

  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(async () => {
    const data = await getCompanyBasicInfo(props.store);
    setStore(data);
  }, [props]);

  useEffect(() => {

    if (lg)
      setSlides(4)
    else if (md)
      setSlides(2)
    else
      setSlides(1)

  }, [md, lg])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/propertiesSite`, config)
      .then((res) => {
        setVenda(res.data);
      });

    config.headers.type = 2;

    axios
      .get(`${process.env.REACT_APP_API_URL}/propertiesSite`, config)
      .then((res) => {
        setLocacao(res.data);
      });
  }, []);

  const controlaInteresse = (
    e,
    interesse,
    resetaForm,
    captcha,
    resetaCaptcha
  ) => {
    e.preventDefault();
    interesse.id = id;

    if (!captcha) {
      toast.warning('Verifique que você não é um robô!.');
      return;
    }

    setOpen(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/interesse`, {
        storeId,
        ...interesse,
        captcha,
      })
      .then(() => {
        toast.success('E-mail enviado com sucesso!');
        resetaForm();
      })
      .catch(() => {
        toast.error('Ocorreu um erro, tente novamente mais tarde.');
      })
      .finally(() => {
        resetaCaptcha();
        setOpen(false);
      });
  };

  const getNumeroWhatsapp = () => {
    let whatsappMOD = whatsapp; // numero sem caracteres
    whatsappMOD = whatsappMOD.replace("-", "").replace("(", "").replace(")", "");
    return whatsappMOD;
  }

  const texto = `Olá, estou interessado(a) em um(a) ${type} para ${deal_type} ${address ? `, ${address}` : ''} ${number ? `, ${number}` : ''} ${neighborhood ? `, ${neighborhood}` : ''} em ${city} - ${state}. \n Segue o link: ${window.location.href}`;
  let whatsappMOD = getNumeroWhatsapp();

  const abrirWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?phone=55${getNumeroWhatsapp()}&text=${texto}`, "_blank");
  }

  const abrirTelefone = () => {
    window.open(`tel:+55${getNumeroWhatsapp()}`);
  }

  return (
    <>
      <Container className="container">
        <h2 className="resultTipo">
          {type} para {deal_type}
        </h2>
        <div className="imagensImovel">
          <CarouselImagens pictures={pictures} />
        </div>
        <div className="boxInfoImobiliaria" >
          <div className="boxInfo" >
            <div className="boxImagem" >
              {store && store.image &&
                <img 
                  src={store.image}
                  alt='logo imobiliaria'
                  className='logoImobiliaria'
                  style={{ borderColor: theme.colors.primary }}
                />
              }
            </div>
            {store && 
              <div className="boxDetalhes" style={{borderColor: central.color1}} >
                <div className='nome' style={{color: central.color1}}>{store.name}</div>
                {/* <div className='creci' >CRECI J3273</div> */}
                <div className='endereco'>{store.address}, {store.number} - {store.neighborhood} CEP {store.cep} - {store.city}</div>
              </div>
            }
          </div>
          <div className="boxContato" >
              <button type='button' className="boxContatoWhatsapp" onClick={() => abrirWhatsapp(store)} >
                <WhatsAppIcon />
                Whatsapp
              </button>
              <button type='button' className="boxContatoTelefone" onClick={() => abrirTelefone(store)} >
                <PhoneIcon/>
                {whatsapp }
              </button>
          </div>
        </div>
        <div className="infosImovel">
          <div className="infosContainer">
            <div className="precoResultTipo">
              <div className="resultTipo invisivel-desktop">
                <h2 className="resultTipo">
                  {type} para {deal_type}
                </h2>
              </div>
              <Typography variant="h2" className="preco" color="primary">
                {parseInt(price, 10).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                })}
              </Typography>
              <div
                className="divPreco"
                style={{ backgroundColor: theme.colors.primary }}
              >
                <p className="resultTipo" color="primary">
                  {type} para {deal_type}
                </p>
              </div>
              <hr />
              {/* {store && store.image &&
                <img src={store.image}
                  alt='logo imobiliaria'
                  className='logoImobiliaria'
                  style={{ borderColor: theme.colors.primary }}
                />
              } */}
            </div>
            <p className="resultEndereco">
              <RoomIcon />
              {address || ''}{address && number ? ', ' : ''}{number || ''}{neighborhood && (number || address ? ' - ' : '')}{neighborhood || ''} | {city} {' - '} {state}
            </p>
            <h3>DESCRIÇÃO</h3>
            <hr />
            {parse(description)}
            <hr />
            <p className="resultInfos">
              {m2 ? (
                <span>
                  <SquareFootIcon />
                  {m2} m²
                </span>
              ) : (
                ''
              )}
              {constructed_m2 ? (
                <span>
                  <SquareFootIcon />
                  {constructed_m2} m² construídos
                </span>
              ) : (
                ''
              )}
              {quartos ? (
                <span>
                  <HotelIcon />
                  {quartos} Quarto(s)
                </span>
              ) : (
                ''
              )}
              {banheiros ? (
                <span>
                  <FaToilet />
                  {banheiros} Banheiro(s)
                </span>
              ) : (
                ''
              )}
              {suites ? (
                <span>
                  <KingBedIcon />
                  {suites} Suíte(s)
                </span>
              ) : (
                ''
              )}
              {vagas ? (
                <span>
                  <DriveEtaIcon />
                  {vagas} Vaga(s) na garagem
                </span>
              ) : (
                ''
              )}
            </p>

            {fci === 1 && deal_type === 'Locação' && (
              <div className="fci">
                <p>IMPORTANTE</p>
                {parse(fci_message)}
              </div>
            )}
            {characteristics.length > 0 && (
              <div className="caracteristicas">
                <h3>CARACTERÍSTICAS</h3>
                <hr />
                {characteristics.map((caracteristica, index) => (
                  <p key={caracteristica.id}>
                    <CheckCircleOutlineIcon className="checkIcon" />
                    <span>{caracteristica.nome}</span>
                  </p>
                ))}
              </div>
            )}
            {youtube_link && (
              <div className="video">
                <h3>VÍDEO</h3>
                <hr />
                <ReactPlayer
                  url={youtube_link}
                  playing
                  muted
                  controls
                  width="100%"
                  height="500px"
                />
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Tooltip title={`Compartilhar imóvel REF${id} no Facebook`}>
                <FacebookShareButton
                  url={`${process.env.REACT_APP_API_URL}/imoveisCrawler/${id}?url=${newUrl}`}
                  className="shareImovel"
                >
                  <FacebookIcon />
                </FacebookShareButton>
              </Tooltip>
            </div>
          </div>
          <div className="interesseContainer">
            <Interesse
              controlaInteresse={controlaInteresse}
              captchaKey={captcha_site_key}
            />
            {whatsapp && (
              <Button
                fullWidth
                variant="contained"
                className="botaoWpp"
                target="novaAbaWpp"
                classes={{ label: classes.label }}
                href={`https://api.whatsapp.com/send?phone=55${whatsappMOD}&text=${texto}`}
              >
                <WhatsAppIcon />
                <span style={{ marginLeft: '4px' }}>Tenho interesse</span>
              </Button>
            )}
          </div>
        </div>

        {deal_type === 'Venda' && (
          <div className="carouselContainer">
            <h2>Imóveis Relacionados</h2>
            <ListagemSlider data={venda} slides={slides} />
          </div>
        )}

        {deal_type === 'Locação' && (
          <div className="carouselContainer">
            <h2>Imóveis Relacionados</h2>
            <ListagemSlider data={locacao} slides={slides} />
          </div>
        )}
      </Container>
      <ToastContainer newestOnTop position="bottom-right" />
      <Carregando open={open} />
    </>
  );
};

export default Imovel;

const ListagemSlider = (props) => {
  const theme = useTheme();
  const sliderRef = useRef(null);

  const qtd = 1;
  const { data, slides } = props;

  const [splitedArr, setSplitedArr] = useState([])

  const setarSplitedArr = () => {
    let index = 0;
    const mat = []
    while (index < data.length) {
      mat.push(data.slice(index, index + slides))
      index += slides
    }

    setSplitedArr(mat)
  }

  useEffect(setarSplitedArr, [data, slides])



  return (
    <div className="listSlider">
      <Slider
        ref={sliderRef}
        infinite
        speed={500}
        autoplaySpeed={10000}
        slidesToShow={qtd}
        arrows={false}
        initialSlide
      >
        {splitedArr.map((array, indexA) => (
          <Box key={indexA} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {array.map((objeto, index) => (
                <Grid key={`${objeto.id}${index}`} item lg={3} md={6} xs={12}>
                  <ImovelCard props={objeto} style_type="vertical" />
                </Grid>
              ))
              }
            </Grid>
          </Box>
        )
        )}
      </Slider>
      {splitedArr.length > 1 &&
        <div className="arrowBtn">
          <button
            type="button"
            className="arrowLeft"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickPrev()}
          >
            <ArrowLeft fontSize="large" />
          </button>
          <button
            type="button"
            className="arrowRight"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickNext()}
          >
            <ArrowRight fontSize="large" />
          </button>
        </div>
      }
    </div>
  )
}