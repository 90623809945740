import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import axios from 'axios';
import { debounce } from 'lodash';
import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useState,
} from 'react';

import Carregando from '../Carregando';
import Precos from './precos';

import './styles.css';

const maxPrice = (value) => (value && value <= 10000000) ? value : 10000000;

const Pesquisa = ({ controlaPesquisa, pesquisaTipo }) => {
  const componentIsMounted = useRef(true);
  const [valorMax, setValorMax] = useState(9999);
  const [open, setOpen] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [caracteristicasTodas, setCaracteristicasTodas] = useState([]);
  const [tiposImovel, setTiposImovel] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [pesquisa, setPesquisa] = useReducer(
    (state, newState) => {
      const localState = {...state, precoMax: maxPrice(state.precoMax)};
      const localNewState = {...newState, precoMax: maxPrice(newState.precoMax)};
      return { ...localState, ...localNewState };
    },
    {
      cidade: -1,
      estado: -1,
      precoMin: 0,
      precoMax: 0,
      tiposImovel: [],
      caracteristicas: [],
      tipo: -1,
      pagina: 1,
    }
  );

  useEffect(() => {
    // alert(JSON.stringify(pesquisaTipo))
    if (Object.keys(pesquisaTipo).length > 0) {
      setPesquisa(pesquisaTipo);
    }

    if (
      'tiposImovel' in pesquisaTipo &&
      !Array.isArray(pesquisaTipo.tiposImovel)
    ) {
      const temp = pesquisaTipo;
      temp.tiposImovel = [parseInt(temp.tiposImovel, 10)];
      setPesquisa(temp);
    }

    if (
      'caracteristicas' in pesquisaTipo &&
      !Array.isArray(pesquisaTipo.caracteristicas)
    ) {
      const temp = pesquisaTipo;
      temp.caracteristicas = [parseInt(temp.caracteristicas, 10)];
      setPesquisa(temp);
    }

    if (pesquisaTipo.estado !== -1) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/getEstadosCidades`, {
          params: { estado: pesquisaTipo.estado },
        })
        .then((res) => {
          setEstados(res.data.estados);
          setCidades(res.data.cidades);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/getEstadosCidades`)
        .then((res) => {
          setEstados(res.data.estados);
        });
    }

    axios.get(`${process.env.REACT_APP_API_URL}/getTiposImovel`).then((res) => {
      
      setTiposImovel(res.data);
    });

    axios
      .get(`${process.env.REACT_APP_API_URL}/getCaracteristicas`)
      .then((res) => {
        setCaracteristicasTodas(res.data);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/getTiposNegocio`)
      .then((res) => {
        setTipos(res.data);
      });

    axios.get(`${process.env.REACT_APP_API_URL}/getPrecos`).then((res) => {
      if (pesquisaTipo.precoMax === 0)
        setPesquisa({ precoMax: maxPrice(res.data.precoMax) });
      setValorMax(maxPrice(res.data.precoMax));
    });
  }, [pesquisaTipo]);

  /**
   * Vai fazer um debounce, ao invés de atualizar o estado toda vez que é trocado o valor,
   * vai fazer isso a cada 1 ms
   */
  const adiaControle = useCallback(
    debounce((objeto) => setPesquisa(objeto), 1),
    []
  );

  const onChangeValorMonetario = (name, value) => {
    setPesquisa({[name]: Number(value)});
    //  if (value >= 0 && value <= valorMax) {
    //     // Quando a pessoa apagar tudo, coloca em 0
    //     if (!value) {
    //       setPesquisa({ [name]: 0 });
    //     } else {
    //       setPesquisa({ [name]: parseInt(value, 10) });
    //     }
    //   }
  }

  /**
   * Função de controle do formulário
   * @param {Event} evento evento disparado
   * @param {[*]} vetorValoresNovos só necessário passar quando o evento não possui name e/ou valor
   * @param {String} tipo tipo do evento, só é necessário passar quando é um evento customizado
   */
  const controlaForm = (evento, vetorValoresNovos, tipo) => {
    const { name, value, checked } = evento.target;

    if (Array.isArray(value)) {
      setPesquisa({ [name]: value });
      return;
    }

    if (name) {
      switch (name) {
        // case 'precoMax':
        // case 'precoMin':
        //   setPesquisa({ [name]: parseInt(value, 10) });
          // if (value >= 0 && value <= valorMax) {
          //   if (!value) {
          //     setPesquisa({ [name]: 0 });
          //   } else {
          //     setPesquisa({ [name]: parseInt(value, 10) });
          //   }
          // }
          // break;
        case 'estado':
          setPesquisa({ [name]: value });
          setPesquisa({ cidade: -1 });

          setOpen(true);
          axios
            .get(`${process.env.REACT_APP_API_URL}/getEstadosCidades`, {
              params: { estado: value },
            })
            .then((res) => {
              setCidades(res.data.cidades);
              setOpen(false);
            });
          break;

        case 'caracteristicas':
          if (checked) {
            setPesquisa({
              [name]: [...pesquisa.caracteristicas, parseInt(value, 10)],
            });
          } else {
            const newArray = pesquisa.caracteristicas;
            newArray.splice(newArray.indexOf(parseInt(value, 10)), 1);
            setPesquisa({ [name]: newArray });
          }
          break;

        default:
          setPesquisa({ [name]: value });
          break;
      }
    }
    // if (vetorValoresNovos && !name) {
    //   switch (tipo) {
    //     case 'preco':
    //       adiaControle({
    //         precoMin: vetorValoresNovos[0],
    //         precoMax: vetorValoresNovos[1],
    //       });
    //       break;
    //     default:
    //       console.error('operação não suportada!');
    //       break;
    //   }
    // }
  };

  return (
    <div className="pesquisaLateral">
      <FormControl className="tipos">
        <InputLabel htmlFor="tipos">Tipo de Negócio</InputLabel>
        <Select
          value={pesquisa.tipo}
          onChange={(e) => controlaForm(e)}
          inputProps={{
            name: 'tipo',
            id: 'tipos',
          }}
        >
          <MenuItem value="-1">Ambos</MenuItem>
          {tipos.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="tiposImovel">
        <InputLabel id="tiposImovel">Tipos do Imóvel</InputLabel>
        <Select
          labelId="tiposImovel"
          multiple
          value={pesquisa.tiposImovel}
          inputProps={{
            name: 'tiposImovel',
            id: 'tiposImovel',
          }}
          onChange={(e) => {

            if(e.target.value.length === 0) {
              e.target.value = [-1];
            } else {
              e.target.value = e.target.value.filter(r => r!==-1);
            }
            

            controlaForm(e);
          }}
          renderValue={(selected) => {
            const nomes = [];
            if(pesquisa.tiposImovel.includes(-1)) {
              nomes.push("Todos");
            }
            tiposImovel.forEach((tipo) => {
              if (selected.indexOf(tipo.id) > -1) {
                nomes.push(tipo.name);
              }
            });
            return nomes.join(', ');
          }}
        >
          <MenuItem key="-1" value="-1" >
              <Checkbox checked={pesquisa.tiposImovel.indexOf(-1) !== -1} />
              <ListItemText primary="Todos" />
          </MenuItem>

          {tiposImovel.map((tipo) => (
            <MenuItem key={tipo.id} value={tipo.id}>
              <Checkbox checked={pesquisa.tiposImovel.indexOf(tipo.id) > -1} />
              <ListItemText primary={tipo.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="estados">
        <InputLabel id="labelCidades" htmlFor="estados">
          Estados
        </InputLabel>
        <Select
          labelId="labelEstados"
          value={pesquisa.estado}
          onChange={(e) => controlaForm(e)}
          inputProps={{
            name: 'estado',
          }}
        >
          <MenuItem value="-1">Todos</MenuItem>
          {estados.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="cidades">
        <InputLabel id="labelCidades" htmlFor="cidades">
          Cidade
        </InputLabel>
        <Select
          labelId="labelCidades"
          value={pesquisa.cidade}
          onChange={(e) => controlaForm(e)}
          disabled={pesquisa.estado === -1}
          inputProps={{
            name: 'cidade',
            id: 'cidades',
          }}
        >
          <MenuItem value="-1">
            {pesquisa.estado !== -1 ? `Todas` : `Selecione o Estado`}
          </MenuItem>
          {cidades.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container spacing={2} className="precoContainer">
        <Grid item className="inputPreco">
          <div style={{ width: '100%', margin: 'auto' }}>
            <CurrencyTextField
                id="precoDe"
                label="De"
                name="precoMin"
                fullWidth
                decimalCharacter=","
                digitGroupSeparator="."
                variant="standard"
                value={pesquisa.precoMin}
                currencySymbol="R$"
                outputFormat="number"
                onChange={(event,value) => onChangeValorMonetario("precoMin",value)}
            />
          </div>
        </Grid>
        <Grid item xs className="gridSlider">
          <Precos
            valorMax={valorMax || 0}
            pesquisa={pesquisa}
            handleFormChange={(event,values) => {
              const [min,max] = values;
              setPesquisa({
                precoMax: max,
                precoMin: min,
              });
            }}
            onKeyUp={controlaForm}
          />
        </Grid>
        <Grid item className="inputPreco">
          <div style={{ width: '100%', margin: 'auto' }}>
            <CurrencyTextField
              id="precoAte"
              label="Até"
              name="precoMax"
              fullWidth
              decimalCharacter=","
              digitGroupSeparator="."
              variant="standard"
              value={pesquisa.precoMax}
              currencySymbol="R$"
              outputFormat="number"
              // onKeyUp={onChangeValorMonetario}
              onChange={(event,value) => onChangeValorMonetario("precoMax",value)}
            />
          </div>
        </Grid>
      </Grid>

      {/* <FormControl className="caracteristicas">
        <FormLabel
          id="caracteristicas"
          style={{ color: 'rgba(0, 0, 0, 0.54) !important' }}
        >
          Características
        </FormLabel>
        <FormGroup>
          {caracteristicasTodas.map((caracteristica) => (
            <FormControlLabel
              key={caracteristica.id}
              control={
                <Checkbox
                  checked={
                    pesquisa.caracteristicas.indexOf(caracteristica.id) > -1
                  }
                  onChange={(e) => controlaForm(e)}
                  name="caracteristicas"
                  value={caracteristica.id}
                />
              }
              label={caracteristica.name}
            />
            //   <Checkbox
            //     checked={
            //       pesquisa.caracteristicas.indexOf(caracteristica.id) > -1
            //     }
            //   />
            //   <ListItemText primary={caracteristica.name} />
            // </MenuItem>
          ))}
        </FormGroup>
      </FormControl> */}

      <div className="botaoContainer">
        <Button
          className="botaoPesquisa"
          variant="contained"
          onClick={() => controlaPesquisa(pesquisa)}
          color="primary"
        >
          <SearchIcon />
          Buscar Imóveis
        </Button>
      </div>
      <Carregando open={open} />
    </div>
  );
};

export default Pesquisa;
