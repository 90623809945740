import { Box, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { debounce } from 'lodash';
import queryString from 'query-string';
import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useState,
} from 'react';
import Geocode from "react-geocode";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../temas/cores';

import './styles.css';

Geocode.setApiKey("AIzaSyBslMeY3qnqiSlPDv5Bo-O8MpwAmpP6p4Q");
Geocode.setLanguage("pt-BR");
Geocode.setLocationType("ROOFTOP");

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Pesquisa = () => {
  const componentIsMounted = useRef(true);
  const history = useHistory();
  const [cidades, setCidades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [valorMax, setValorMax] = useState(9999);
  const [imagens, setImagens] = useState([]);
  const [tiposImovel, setTiposImovel] = useState([]);
  const [verificandoCidade, setVerificandoCidade] = useState(true);
  const [loading, setLoading] = useState(true);
  
  const [pesquisa, setPesquisa] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      cidade: -1,
      estado: -1,
      precoMin: 0,
      precoMax: 0,
      tipo: -1,
      tiposImovel: -1,
    }
  );
  const [tab, setTab] = React.useState(1);

  useEffect(() => {
    setPesquisa({
      tipo: tab,
    })
  }, [tab])

  function removerAcentuacaoECaracteresEspeciais(texto) {
    return texto.replace(/[^\w\s]/gi, '').replace(/[áàãâä]/gi, 'a').replace(/[éèêë]/gi, 'e').replace(/[íìîï]/gi, 'i').replace(/[óòõôö]/gi, 'o').replace(/[úùûü]/gi, 'u').replace(/[ç]/gi, 'c');
  }

  useEffect(async () => {
    const imagensPromise = axios.get(`${process.env.REACT_APP_API_URL}/bannersSite`);
    const cidadesPromise = axios.get(`${process.env.REACT_APP_API_URL}/getEstadosCidadesInicial`);
    const tiposPromise = axios.get(`${process.env.REACT_APP_API_URL}/getTiposNegocio`);
    const tiposImovelPromise = axios.get(`${process.env.REACT_APP_API_URL}/getTiposImovel`);
    const pesquisaPromise = axios.get(`${process.env.REACT_APP_API_URL}/getPrecos`);

    const [
      imagensRes, 
      cidadesRes, 
      tiposRes, 
      tiposImovelRes, 
      pesquisaRes
    ] = await Promise.all([
      imagensPromise,
      cidadesPromise, 
      tiposPromise, 
      tiposImovelPromise, 
      pesquisaPromise
    ]);
    
    setImagens(imagensRes.data);
    setCidades(cidadesRes.data.cidades);
    setTipos(tiposRes.data);
    setTiposImovel(tiposImovelRes.data);
    setPesquisa({ precoMax: pesquisaRes.data.precoMax });
    setValorMax(pesquisaRes.data.precoMax);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        Geocode.fromLatLng(lat, lng).then(
          (response) => {
            const responseText = removerAcentuacaoECaracteresEspeciais(JSON.stringify(response));

            const cidadeEncontrada = cidadesRes.data.cidades.find(cidade => {
              const cidadeTxt = removerAcentuacaoECaracteresEspeciais(cidade.split(" ")[0]);
              return responseText.indexOf(cidadeTxt) !== -1
            });
            setPesquisa({
              cidade: cidadeEncontrada,
            });

            console.log(cidadeEncontrada, "cidade Encontrada");
            
          },
          (error) => {
            console.error(error);
          }
        );
        setVerificandoCidade(false);
      }, () => {
        setVerificandoCidade(false);
        console.log("Não foi possivel capturar a cidade do usuário");
      });
    } else setVerificandoCidade(false);

    setLoading(false);
    
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  /**
   * Controle da pesquisa, faz uma requisição pro backend, recebe um vetor com as
   * informações das propriedades e então faz o roteamento pra página de pesquisa
   */
  const controlaPesquisa = () => {
    const newPesquisa = pesquisa;
    if (pesquisa.cidade !== -1)
      [newPesquisa.cidade, newPesquisa.estado] = pesquisa.cidade.split(' - ');

    const pesquisaURL = queryString.stringify(newPesquisa);

    history.push(`/pesquisa?${pesquisaURL}`);
  };

  /**
   * Vai fazer um debounce, ao invés de atualizar o estado toda vez que é trocado o valor,
   * vai fazer isso a cada 1 ms
   */
  const adiaControle = useCallback(
    debounce((objeto) => setPesquisa(objeto), 1),
    []
  );

  /**
   * Função de controle do formulário
   * @param {Event} evento evento disparado
   * @param {[*]} vetorValoresNovos só necessário passar quando o evento não possui name e/ou valor
   * @param {String} tipo tipo do evento, só é necessário passar quando é um evento customizado
   */
  const controlaForm = (evento, vetorValoresNovos, tipo) => {
    const { name, value } = evento.target;

    if (name && !vetorValoresNovos) {
      switch (name) {
        case 'precoMax':
        case 'precoMin':
          if (value >= 0 && value <= valorMax) {
            // Quando a pessoa apagar tudo, coloca em 0
            if (!value) {
              setPesquisa({ [name]: 0 });
            } else {
              setPesquisa({ [name]: parseInt(value, 10) });
            }
          }
          break;
        default:
          setPesquisa({ [name]: value });
          break;
      }
    }
    if (vetorValoresNovos && !name) {
      switch (tipo) {
        case 'preco':
          adiaControle({
            precoMin: vetorValoresNovos[0],
            precoMax: vetorValoresNovos[1],
          });
          break;
        default:
          console.error('operação não suportada!');
          break;
      }
    }
  };

  if(loading) return (
    <SkeletonTheme 
      baseColor={COLORS.skeletonBaseColor} 
      highlightColor={COLORS.skeletonHighlightColor}
    >
      <div className="container">
        <Skeleton className="loading" count={1}/>
      </div>
    </SkeletonTheme>
  );

  return (
    <div
      className="background-filter"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* {JSON.stringify(pesquisa)} */}
      <div className="pesquisa container">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example">
          {tipos.map((type) => (
              <Tab label={type.name} value={type.id} {...a11yProps(0)} />
          ))}
          </Tabs>
        </Box>

        <Grid container spacing={2} className="precoContainer">
          <Grid item lg={6} xs={12}>
            <FormControl className="cidades">
              <InputLabel id="labelCidades" htmlFor="cidades">
                Cidade
              </InputLabel>
              <Select
                labelId="labelCidades"
                value={pesquisa.cidade}
                onChange={(e) => controlaForm(e)}
                inputProps={{
                  name: 'cidade',
                  id: 'cidades',
                }}
              >
                <MenuItem value="-1" disabled>
                   {verificandoCidade ? 'Buscando cidades próximas...' : 'Todas'}
                </MenuItem>
                {cidades.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormControl className="tipos">
              <InputLabel htmlFor="tipos">Tipo de imóvel</InputLabel>
              <Select
                value={pesquisa.tiposImovel}
                onChange={(e) => controlaForm(e)}
                inputProps={{
                  name: 'tiposImovel',
                  id: 'tiposImovel',
                }}
              >
                <MenuItem value="-1" disabled>
                  Todos
                </MenuItem>
                {tiposImovel.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item className="inputPreco">
            <div style={{ width: '64px', margin: 'auto' }}>
              <TextField
                id="precoDe"
                label="De"
                name="precoMin"
                fullWidth
                type="number"
                value={pesquisa.precoMin}
                onChange={(e) => controlaForm(e)}
              />
            </div>
          </Grid>
          <Grid item xs className="gridSlider">
            <Precos
              valorMax={valorMax}
              pesquisa={pesquisa}
              handleFormChange={controlaForm}
              onKeyUp={controlaForm}
            />
          </Grid>
          <Grid item className="inputPreco">
            <div style={{ width: '64px', margin: 'auto' }}>
              <TextField
                id="precoAte"
                label="Até"
                name="precoMax"
                fullWidth
                type="number"
                value={pesquisa.precoMax}
                onChange={(e) => controlaForm(e)}
              />
            </div>
          </Grid> */}
          <Grid item xs={12}>
            <div className="botaoContainer">
              <Button
                className="botaoPesquisa"
                variant="contained"
                color="primary"
                onClick={controlaPesquisa}
              >
                <SearchIcon />
                Buscar Imóveis
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Pesquisa;
