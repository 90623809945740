import Box from '@material-ui/core/Box';
import axios from 'axios';
import React, { memo, useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { useTitle } from '../../helpers/tittle';
import Imovel from './container';

import './styles.css';

const Resultado = ({
  central
}) => {
  const { id, store } = useParams();
  const [imovel, setImovel] = useState([{}]);
  const [busca, setBusca] = useState(false);
  const [loading, setLoading] = useState(true);

  useTitle("Resultado");

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/propertiesSite/${id}?store=${store}`)
    .then((res) => {
        setImovel(res.data);
        setBusca(true);
        setLoading(false);
      });
  }, [id]);

  // Caso não existir
  if (busca && !imovel.length) {
    return <Redirect to="/" />;
  }

  return (
    <div className="resultContainer">
      {imovel.map((objeto, index) => (
        <Box key={objeto.id} padding={index === 0 ? 0 : 2}>
          <Imovel key={objeto.id} props={Object.assign(objeto, {loading})} central={central} />
        </Box>
      ))}
    </div>
  );
};

export default memo(Resultado);
