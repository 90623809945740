import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import useWindowDimensions from '../../helpers';
import { useTitle } from '../../helpers/tittle';
import imagem1 from '../../images/imob1.jpg';
import imagem2 from '../../images/imob2.jpg';
import imagem3 from '../../images/imob3.jpg';
import Servicos from '../Home/Servicos';
import './styles.css';

const Empresa = () => {
  const imagens = [
    {
      original: imagem1,
      thumbnail: imagem1,
      title: 'Central de Imobiliárias',
      // description: 'Lorem ipsum is placeholder text commonly used in the graphic',
    },
    {
      original: imagem2,
      thumbnail: imagem2,
      title: '',
      description: '',
    },
    {
      original: imagem3,
      thumbnail: imagem3,
      title: '',
      description: '',
    },
  ];

  useTitle("Empresa");

  const local =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

  const { height } = useWindowDimensions();

  let mostraNav = false;

  if (height > 600) {
    mostraNav = true;
  } else {
    mostraNav = false;
  }

  let mostraThumbs = true;

  if (document.mozFullScreen || document.webkitIsFullScreen)
    mostraThumbs = false;

  return (
    <Container className="containerEmpresa">
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <div className="imagensEmpresa">
            <ImageGallery
              items={imagens}
              showIndex
              showNav={mostraNav}
              showThumbnails={mostraThumbs}
              showPlayButton={false}
            />
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <h2>A Empresa</h2>
          <p style={{ padding: '0 20px' }}>{local}</p>
        </Grid>
      </Grid>
      {/* <Servicos /> */}
    </Container>
  );
};

export default Empresa;
