import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import useWindowDimensions from '../../helpers';

import './styles.css';

const CarouselImagens = ({ pictures }) => {
  const { height } = useWindowDimensions();

  let mostraNav = false;

  if (height > 600) {
    mostraNav = true;
  } else {
    mostraNav = false;
  }

  pictures.forEach((fotos) => {
    fotos.original = fotos.src;
    if (!fotos.thumbnail || fotos.thumbnail.indexOf('no-image.png') !== -1)
      fotos.thumbnail = fotos.src;
  });

  let mostraThumbs = true;
  let thumbsRight = 'left';

  if (document.mozFullScreen || document.webkitIsFullScreen)
    mostraThumbs = false;
  thumbsRight = 'left';

  return (
    <ImageGallery
      items={pictures}
      showIndex
      showNav={mostraNav}
      showThumbnails={mostraThumbs}
      showPlayButton={false}
      thumbnailPosition={thumbsRight}
    />
  );
};

export default CarouselImagens;
