import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import email from 'email-validator';
import React, { useReducer, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { mascaraTelefoneOuCelular } from '../../helpers/masks';

const useStyles = makeStyles({
  label: {
    color: 'white',
  },
});

const Interesse = ({ controlaInteresse, captchaKey }) => {
  const classes = useStyles();
  const [erroEmail, setErroEmail] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [interesse, setInteresse] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      nome: '',
      telefone: '',
      email: '',
    }
  );

  const controlaForm = (evento) => {
    const { name, value } = evento.target;

    setInteresse({ [name]: value });
  };

  const resetaForm = () => {
    setInteresse({ nome: '', telefone: '', email: '' });
  };

  const resetaCaptcha = () => {
    setCaptcha(false);
    window.grecaptcha.reset();
  };

  const controlaEmail = (evento) => {
    const { name, value } = evento.target;
    setInteresse({ [name]: value });

    if (email.validate(value)) {
      setErroEmail(false);
    } else {
      setErroEmail(true);
    }
  };

  return (
    <form
      onSubmit={(e) =>
        controlaInteresse(e, interesse, resetaForm, captcha, resetaCaptcha)
      }
      className="interesse"
    >
      <div>Tem interesse neste imóvel? Entre em contato!</div>
      <FormControl fullWidth>
        <TextField
          required
          id="nome"
          label="Nome"
          name="nome"
          value={interesse.nome}
          onChange={(e) => controlaForm(e)}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          required
          id="telefone"
          label="Telefone"
          name="telefone"
          type="phone"
          value={interesse.telefone}
          InputProps={{
            inputComponent: mascaraTelefoneOuCelular,
          }}
          onChange={(e) => controlaForm(e)}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          error={erroEmail}
          helperText={erroEmail ? 'Email inválido ou incompleto' : ''}
          type="email"
          value={interesse.email}
          onChange={(e) => controlaEmail(e)}
        />
      </FormControl>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center !important',
          alignItems: 'center',
          width: '100%',
          padding: '20px 0',
          overflow: 'hidden'
        }}
      >
        <ReCAPTCHA
          style={{width: '100%', alignSelf: 'center'}}
          size='normal'
          sitekey={captchaKey}
          onChange={(value) => setCaptcha(value)}
          onErrored={() => setCaptcha(false)}
          onExpired={() => setCaptcha(false)}
        />
      </div>
      <div className="botaoInteresse">
        <Button
          type="submit"
          className="botaoInteresse"
          variant="contained"
          color="primary"
          classes={{ label: classes.label }}
          // onClick={() => controlaInteresse(interesse)}
        >
          Entrar em Contato
        </Button>
      </div>
    </form>
  );
};

export default Interesse;
