import { Divider, useTheme, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ArrowLeft, ArrowRight, ViewAgenda } from '@material-ui/icons';
import axios from 'axios';
import React, { memo, useState, useEffect, useRef } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ImovelCard from '../../../components/ImovelCard';
import useWindowDimensions from '../../../helpers';

import './styles.css';
import { COLORS } from '../../../temas/cores';

const Listagem = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [slides, setSlides] = useState(4);
  const [venda, setVenda] = useState([]);
  const [locacao, setLocacao] = useState([]);
  const [loading, setLoading] = useState(true);

  const config = {
    headers: {
      type: 1,
    },
  };

  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    
    if(lg)
      setSlides(4)
    else if(md)
      setSlides(2)
    else
      setSlides(1)

  }, [md, lg])



  useEffect(async () => {
    const vendaPromise = axios.get(`${process.env.REACT_APP_API_URL}/propertiesSite`, { headers: { type: 1 } });
    const LocacaoPromise = axios.get(`${process.env.REACT_APP_API_URL}/propertiesSite?loca=1`, { headers: { type: 2 } });

    const [vendaRes, LocacaoRes] = await Promise.all([vendaPromise, LocacaoPromise]);

    setVenda(vendaRes.data);
    setLocacao(LocacaoRes.data);
    setLoading(false);

  }, []);

  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);

  if(loading) return (
    <>
      <div id="ImoveisVendaLocacao">
        <SkeletonTheme
          baseColor={COLORS.skeletonBaseColor}
          highlightColor={COLORS.skeletonHighlightColor}
          >
          <Skeleton className="loadingTitle" count={1}/>
          <Skeleton className="loadingItens" count={1}/>
        </SkeletonTheme>
      </div>
      <div id="ImoveisVendaLocacao">
        <SkeletonTheme
          baseColor={COLORS.skeletonBaseColor}
          highlightColor={COLORS.skeletonHighlightColor}
          >
          <Skeleton className="loadingTitle" count={1}/>
          <Skeleton className="loadingItens" count={1}/>
        </SkeletonTheme>
      </div>
    </>
  );

  return (
    <>
      {venda && venda.length > 0 && (
        <div className="carouselContainer">
          <h2>Imóveis para Venda</h2>

          <ListagemSlider data={venda} slides={slides} />

          <div className="link_vermais">
            <Button color="primary">
              <Link to="/pesquisa?tipo=1">Ver mais imóveis para venda</Link>
            </Button>
          </div>
        </div>
      )}
      {locacao && (
        <div className="carouselContainer">
          <h2>Imóveis para Locação</h2>
          <ListagemSlider data={locacao} slides={slides} />

          <div className="link_vermais">
            <Button color="primary">
              <Link to="/pesquisa?tipo=2">Ver mais imóveis para Locação</Link>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Listagem);

const ListagemSlider = (props) => {
  const theme = useTheme();
  const sliderRef = useRef(null);

  const qtd = 1;
  const { data, slides } = props;

  const [splitedArr, setSplitedArr] = useState([])

  const setarSplitedArr = () => {
    let index = 0;
    const mat = []
    while (index < data?.length) {
      mat.push(data.slice(index, index + slides))
      index += slides
    }

    setSplitedArr(mat)
  }

  useEffect(setarSplitedArr, [data, slides])

  return (
    <div className="listSlider">
      <Slider
        ref={sliderRef}
        infinite
        speed={500}
        autoplaySpeed={10000}
        slidesToShow={qtd}
        arrows={false}
        initialSlide
      >
        {splitedArr.map((array, indexA) => (
          <Box key={indexA} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {array.map((objeto, index) => (
                <Grid key={`${objeto.id}${index}`} item lg={3} md={6} xs={12}>
                  <ImovelCard props={objeto} style_type="vertical" />
                </Grid>
              ))
              }
            </Grid>
          </Box>
        )
        )}
      </Slider>
      { splitedArr?.length > 1 && 
        <div className="arrowBtn">
          <button
            type="button"
            className="arrowLeft"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickPrev()}
          >
            <ArrowLeft fontSize="large" />
          </button>
          <button
            type="button"
            className="arrowRight"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickNext()}
          >
            <ArrowRight fontSize="large" />
          </button>
        </div>
      }
    </div>
  )
}