import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

import tytotech from '../../images/logo-tytotech.svg';
import './styles.css';

export default function Copyright({ nome }) {
  const theme = useTheme();
  const data = new Date();

  return (
    <footer
      style={{
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: theme.colors.primary,
        filter: 'brightness(80%)',
      }}
    >
      <Container>
        <Grid className="container-footer" container spacing={2}>
          <Grid item lg={6}>
            <span style={{ color: 'white' }}>
              ©{data.getFullYear()} {nome || ''} - Todos os direitos reservados.{' '}
            </span>
          </Grid>
          <Grid
            item
            lg={6}
            style={{
              textAlign: 'right',
            }}
          >
            <a
              style={{
                marginLeft: '5px',
                color: 'white',
                display: 'flex',
                justifyContent: 'flex-end',
                textDecoration: 'none',
              }}
              href="https://tytotech.com.br/"
            >
              <img
                src={tytotech}
                alt="Tytotech"
                width="85px"
                style={{ marginRight: '5px' }}
              />{' '}
              {/* - {process.env.REACT_APP_VERSION} */}
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
