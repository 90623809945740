import { useMediaQuery, useTheme } from '@material-ui/core';
import '@brainhubeu/react-carousel/lib/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ArrowLeft, ArrowRight, ScreenLockLandscapeTwoTone } from '@material-ui/icons';
import axios from 'axios';
import React, { memo, useEffect, useRef, useState, useMemo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Slider from 'react-slick';

import ImovelCard from '../../../components/ImovelCard';
import { COLORS } from '../../../temas/cores';

const Destaque = () => {
  const theme = useTheme();

  const [destaque, setDestaque] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/propriedadesDestaque`)
      .then((res) => {
        setDestaque(res.data);
        setLoading(false);
      });
  }, []);
  

  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const quantidadeItens = useMemo(() =>{
    if(lg){
      return 4
    }

    if(md){
      return 2
    }
    return 1;
  }, [lg, md])

  const sliderRef = useRef(null);

  if (destaque){
    const groups = [];

    for(let i = 0; i < destaque.length; i += quantidadeItens)
      groups.push(destaque.slice(i, i + quantidadeItens));

    if(loading) return (
      <div id="Destaque">
        <SkeletonTheme
          baseColor={COLORS.skeletonBaseColor}
          highlightColor={COLORS.skeletonHighlightColor}
          >
          <Skeleton className="loadingTitle" count={1}/>
          <Skeleton className="loadingItens" count={1}/>
        </SkeletonTheme>
      </div>
    );

    return (
      <div id="Destaque">
        <h1 className='titulo'>Imóveis em Destaque</h1>
        <Slider
          ref={sliderRef}
          infinite
          speed={500}
          autoplaySpeed={10000}
          slidesToShow={1}
          arrows={false}
          initialSlide
        >
          {groups.map((grupo,index)=>(
            <Box key={`destque-${index}`} sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
              {grupo.map((objeto)=>(
                <Grid key={`${objeto.id}${index}`} item lg={3} md={6} xs={12}>
                  <div className="containerImoveis">
                    <span className="tagDestaque" style={{ backgroundColor: theme.colors.primary }}>
                      DESTAQUE
                    </span>
                    <ImovelCard props={objeto} style_type="vertical" />
                  </div>
                </Grid>
              ))}
              </Grid>
            </Box>
          ))}
        </Slider>
        { groups.length > 1 && 
        <div className="arrowBtn">
          <button
            type="button"
            className="arrowLeft"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickPrev()}
          >
            <ArrowLeft fontSize="large" />
          </button>
          <button
            type="button"
            className="arrowRight"
            style={{ backgroundColor: theme.colors.primary }}
            onClick={() => sliderRef.current.slickNext()}
          >
            <ArrowRight fontSize="large" />
          </button>
        </div>
      }
      </div>
    );
  }
  return <div />;
};

export default memo(Destaque);
