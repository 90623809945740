import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Button,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';

import './styles.css';

const Menu = ({ props }) => {
  // url vai pegar a url absoluta(base) da nossa aplicação, ao invés de pegar a relativa
  const { url } = useRouteMatch();
  const { image, name } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (option) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(option);
  };

  // para colocaro background no drawer
  const classes = makeStyles({
    MuiDrawer: {
      backgroundColor: theme.colors.tertiary,
    },
  });

  const styles = classes();

  const rotas = [
    { link: '', descricao: 'Home' },
    // { link: 'empresa', descricao: 'A Empresa' },
    // { link: 'cadastro', descricao: 'Cadastro' },
    { link: 'pesquisa?tipo=1', descricao: 'Venda' },
    { link: 'pesquisa?tipo=2', descricao: 'Locação' },
    // { link: 'contato', descricao: 'Contato' },
  ];

  return (
    <AppBar position="sticky">
      <Toolbar
        className="Header"
        style={{ backgroundColor: theme.colors.tertiary }}
      >
        <IconButton onClick={toggleDrawer(true)} edge="start" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <a href="/" title={name}>
          <img className="logo" src={image} alt="Logo" />
        </a>
        <div className="links">
          {rotas.map((rota) => (
            <Typography key={`header_${rota.descricao}`} variant="h6">
              <Link title={rota.descricao}
                style={{
                  color:
                    rota.link === 'contato' ? 'white' : theme.colors.primary,
                  backgroundColor:
                    rota.link === 'contato' ? theme.colors.primary : '',
                }}
                to={rota.link ? `${url}${rota.link}` : '/'}
              >
                {rota.descricao}
              </Link>
            </Typography>
          ))}
        </div>
        <Drawer
          className="Drawer"
          open={open}
          classes={{ paper: styles.MuiDrawer }}
          onClose={toggleDrawer(false)}
        >
          {rotas.map((rota) => (
            <Button key={`header_${rota.link}`} onClick={toggleDrawer(false)}>
              <Link title={rota.descricao}
                style={{ color: theme.colors.primary }}
                to={rota.link ? rota.link : '/'}
              >
                {rota.descricao}
              </Link>
            </Button>
          ))}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Menu;
