import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import email from 'email-validator';
import React, { useReducer, useState, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';

import Carregando from '../../components/Carregando';
import { useTitle } from '../../helpers/tittle';
import './styles.css';

export default function Contato({ props }) {
  const {
    address,
    phones,
    city,
    state,
    number,
    name: imobName,
    cep,
    email: imobEmail,
    captcha_site_key,
  } = props;

  const [contato, setContato] = useReducer(
    (reducerState, newState) => ({ ...reducerState, ...newState }),
    {
      nome: '',
      email: '',
      cidade: '',
      uf: '',
      assunto: '',
      mensagem: '',
    }
  );
  const [open, setOpen] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [erroEmail, setErroEmail] = useState(false);

  useTitle("Contato");

  /**
   * Controle do contato, faz uma requisição pro backend, recebe um vetor com as
   * informações das propriedades e então faz o roteamento pra página de contato
   */
  const controlaContato = () => {
    if (!captcha) {
      toast.warning('Verifique que você não é um robô!.');
      return;
    }

    setOpen(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/contato`, { ...contato, captcha })
      .then(() => {
        toast.success('E-mail enviado com sucesso!');
        window.grecaptcha.reset();
        setCaptcha(false);

        setContato({
          nome: '',
          email: '',
          cidade: '',
          uf: '',
          assunto: '',
          mensagem: '',
        });
      })
      .catch(() => {
        toast.error('Ocorreu um erro, tente novamente mais tarde.');
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const controlaEmail = (evento) => {
    const { name, value } = evento.target;
    setContato({ [name]: value });
    if (email.validate(value)) {
      setErroEmail(false);
    } else {
      setErroEmail(true);
    }
  };

  /**
   * Wrapper do reducer, função padrão para alterar um valor do reducer
   * @param {Evento} evento evento disparado pelo input
   */
  const controlaForm = (evento) => {
    const { name, value } = evento.target;
    if (name === 'uf') {
      if (value.length <= 2) {
        setContato({ [name]: value });
      }
    } else {
      setContato({ [name]: value });
    }
  };

  /**
   * por ora só tá fazendo o log, mas logo logo vai mandar pro back
   * @param {Event} evento evento de submit
   */
  const controlaSubmit = (evento) => {
    evento.preventDefault();
    controlaContato();
  };

  const reg = /src=".*?"/;
  let { linkMaps } = props;

  // Array destructuring, desestruturando o array, vai pegar na posição 0
  [linkMaps] = reg.exec(linkMaps);
  // aqui vai pegar na posição 1, assim limpando o input
  [, linkMaps] = linkMaps.split('"');

  return (
    <div className="formContatoContainer">
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} className="mapaContainer">
            <LazyLoadComponent>
              <iframe
                title="mapa"
                src={linkMaps}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              />
            </LazyLoadComponent>
            {/* <p className="informacoes">
                <span>{imobName}</span>
                <span>
                  Endereço:{' '}
                  <span>
                    {address}, {number}
                  </span>
                </span>
                <span>
                  Fone(s):{' '}
                  {phones
                    ? phones.map((telefone) => {
                        if (phones.length < phones.length - 1)
                          return (
                            <span key={telefone.phone}>
                              <a href={`tel:${telefone.phone}`}>
                                {telefone.phone}
                              </a>
                              ,{' '}
                            </span>
                          );
                        return (
                          <a
                            key={telefone.phone}
                            href={`tel:${telefone.phone}`}
                          >
                            {telefone.phone}
                          </a>
                        );
                      })
                    : ''}
                </span>
                <span>CEP: {cep}</span>
                <span>
                  {city} - {state}
                </span>
                <span>
                  <span>E-mail: </span>
                  <a href={`mailto:${imobEmail}`}>{imobEmail}</a>
                </span>
              </p> */}
          </Grid>
          <Grid item lg={6} sm={12}>
            <form
              action="submit"
              className="formContato"
              onSubmit={controlaSubmit}
            >
              <div className="inputContainer">
                <div>
                  <TextField
                    className="campo-form"
                    required
                    id="nome"
                    label="Nome"
                    name="nome"
                    value={contato.nome}
                    onChange={controlaForm}
                  />
                  <TextField
                    required
                    className="campo-form"
                    id="cidade"
                    label="Cidade"
                    name="cidade"
                    value={contato.cidade}
                    onChange={controlaForm}
                  />
                  <TextField
                    required
                    id="uf"
                    className="campo-form"
                    label="UF"
                    name="uf"
                    value={contato.uf}
                    onChange={controlaForm}
                  />
                  <TextField
                    required
                    className="campo-form"
                    type="email"
                    error={erroEmail}
                    helperText={erroEmail ? 'Email inválido ou incompleto' : ''}
                    id="email"
                    label="Email"
                    name="email"
                    value={contato.email}
                    onChange={controlaEmail}
                  />
                  <TextField
                    required
                    className="campo-form"
                    id="assunto"
                    label="Assunto"
                    name="assunto"
                    value={contato.assunto}
                    onChange={controlaForm}
                    fullWidth
                  />
                  <TextField
                    required
                    className="campo-form"
                    minRows="5"
                    variant="outlined"
                    multiline
                    id="mensagem"
                    label="Mensagem"
                    name="mensagem"
                    value={contato.mensagem}
                    onChange={controlaForm}
                    fullWidth
                  />
                </div>
              </div>
              <div className="recaptcha-line">
                <div className="recaptcha-box">
                  <ReCAPTCHA
                    sitekey={captcha_site_key}
                    onChange={(value) => setCaptcha(value)}
                    onErrored={() => setCaptcha(false)}
                    onExpired={() => setCaptcha(false)}
                  />
                </div>
                <div className="btn">
                  <Button
                    className="botaoEnviarContato"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Enviar Contato
                  </Button>
                </div>
              </div>
            </form>
          </Grid>
          <ToastContainer newestOnTop position="bottom-right" />
          <Carregando open={open} />
        </Grid>
      </Container>
    </div>
  );
}
