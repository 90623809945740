import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import adm from '../../../images/accounting.png';
import conta from '../../../images/bank-account.png';
import venda from '../../../images/conta-bancaria.png';
import consorcio from '../../../images/documents.png';
import moeda from '../../../images/dollar-coin.png';
import hand from '../../../images/hand.png';
import local from '../../../images/museum.png';
import review from '../../../images/review.png';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  tituloItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function Servicos() {
  const classes = useStyles();

  return (
    <div className="servicos">
      <h2>Outros Serviços</h2>

      <Grid container spacing={2}>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={hand} alt="Mão com moeda" className="img-icone" />
            <h3 className={classes.tituloItem}>Financiamento Habitacional</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={moeda} alt="Moeda" className="img-icone" />
            <h3 className={classes.tituloItem}>Empréstimo Consignado</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={conta} alt="Conta" className="img-icone" />
            <h3 className={classes.tituloItem}>Abertura de Conta</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={consorcio} alt="Consórcio" className="img-icone" />
            <h3 className={classes.tituloItem}>Consórcio</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={venda} alt="Vendas" className="img-icone" />
            <h3 className={classes.tituloItem}>Vendas</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={adm} alt="Administração" className="img-icone" />
            <h3 className={classes.tituloItem}>Administração</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={review} alt="Avaliação" className="img-icone" />
            <h3 className={classes.tituloItem}>Avaliação</h3>
          </Paper>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Paper className={classes.paper}>
            <img src={local} alt="local" className="img-icone" />
            <h3 className={classes.tituloItem}>Locação</h3>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
